@import 'tools';

.StripeElement {
  @include border-radius(6px);
  box-sizing: border-box;
  background-color: white;
  height: 40px;
  padding: 6px 12px;;
  border: solid 1px rgba(0,0,0,0.33);

  > div {
  	top: 50%;
    transform: translateY(-50%);
  }
}
