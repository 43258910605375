.checkbox-drop {
  div {
    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #979197;
    }
  }

  label > div {
    margin-right: 0.6rem;
    max-width: 15px;
    max-height: 15px;
  }

  input {
    background-color: rgba(232, 238, 243, 0.75);
    border-radius: 20px;
    border: none;
    font-size: 0.75rem;
  }

  button {
    border-top: 0.2px solid #c4c4c4;
    background-color: white;

    span {
      font-size: 0.75rem;
    }

    @media (hover: hover) and (pointer: fine), only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
      &:hover {
        label {
          span {
            color: #788af7;
          }

          div > div {
            border: 1px solid #788af7 !important;
          }
        }
      }
    }
  }

  button > div {
    padding: 0.6rem 0.75rem;
  }
}
