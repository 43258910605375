.react-tags {
  position: relative;
  padding: 0.25rem 1rem;
  border: solid 1px #6a6e80;
  border-radius: 0.25rem;
  background: white;
  font-size: 1rem;
  cursor: text;
}

@media (min-width: 48.0625em) {
  .react-tags {
    width: 90%;
  }
}

.react-tags.is-active {
  border-color: #788af7;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags__label {
  display: none;
}

.react-tags__list {
  display: inline;
  padding: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: #4f46e5;
}

.react-tags__tag::after {
  content: '';
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(10% 0, 0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%);
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #7c7d86;
}

.react-tags__tag:hover::after {
  background-color: #ffffff;
}

.react-tags__combobox {
  display: inline-block;
  padding: 0.375rem 0.25rem;
  max-width: 100%;
}

.react-tags__combobox-input {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  top: calc(14px + 0.9rem + 1rem);
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 1.5rem 0px;
  width: 95%;
  z-index: 9999;
  margin: 4px -1px;
  padding: 12px;
  list-style: none;
  background: white;
  border-radius: 0.375rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__listbox-option {
  font-family: 'Figtree', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.7rem 0.45rem;
  color: #788af7;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: rgba(242, 243, 254, 0.7);
}

.react-tags__listbox-option[aria-disabled='true'] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option-highlight {
  background: none;
  font-weight: 700;
  color: #788af7;
}

.authTagsContainer {
  .react-tags {
    width: unset;
    padding: 0.15rem 1rem;
    border: solid 1px #D0D0D0;
    border-radius: 0.35rem;
  }

  .react-tags.is-active {
    border-color: #174EA6;
  }

  .react-tags__listbox-option {
    color: #3C3C3C;
  }

  .react-tags__listbox-option-highlight {
    font-weight: 600;
    color: #004FFE;
  }

  .react-tags__listbox {
    padding: 0;
  }

  .react-tags__listbox-option:hover {
    background: #F0F6FF;
    color: #004FFE;
  }

  button > span {
    color: #3C3C3C;
  }
}
