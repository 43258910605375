@mixin letter-spacing($spacing) {
  -webkit-letter-spacing: $spacing;
  -moz-letter-spacing: $spacing;
  -ms-letter-spacing: $spacing;
  letter-spacing: $spacing;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radiuses($top-left, $top-right, $bottom-right, $bottom-left) {
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color) {
  -webkit-box-shadow: $h-offset $v-offset $blur $spread $color;
  -moz-box-shadow: $h-offset $v-offset $blur $spread $color;
  box-shadow: $h-offset $v-offset $blur $spread $color;
}

@mixin timeline-circle-format($color) {
  position: relative;

  &:after {
    content: '';
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 5px solid #4EC4C2;
    background-color: $color;
    @include border-radius(50%);
  }
}

%shadow {
  @include box-shadow(1px, 1px, 2px, 0px, rgba(0,0,0,0.14));
}

%shadow-still {
  @include box-shadow(0px, 0px, 3px, 0px, rgba(0,0,0,0.24));
}

%shadow-form {
  @include box-shadow(2px, 2px, 4px, 0px, rgba(0,0,0,0.14));
}

:root {
  --primary-text: #3C3C3C;
  --app-highlight: #004FFE;
  --app-warning: #ef901e;
  --app-success: #299914;
  --search-field-color: rgba(242, 243, 254, 0.7);
  --search-selection-color: rgba(120, 138, 247, 0.2);
  --alternate-row-bg: rgba(242, 243, 254, 0.5);
  --dashboard-segment-color: rgba(120, 138, 247, 0.2);
  --dashboard-segment-hover-color: rgba(120, 138, 247, 0.33);
  --dashboard-bg: rgba(242, 243, 254, 0.7);
  --dashboard-bg-darken: rgba(120, 138, 247, 0.33);
  --new-page-bg: rgba(242, 243, 254, 0.7);
  --light-grey: #efefef;
  --alpha-white: rgba(255, 255, 255, 0.7);
  --pagination-color: rgba(120, 138, 247);
  --pagination-hover-color: rgba(120, 138, 247, 0.6);
  --dusty-blue: rgba(84, 141, 183, 0.1);
  --box-shadow: 0px 7px 18px rgba(13, 38, 76, 0.19);
  --box-shadow-hover: 0 14px 30px rgba(13, 38, 76, 0.25);
  --box-shadow-value: 1px 2px 8px;
  --box-shadow-hover-value: 1px 4px 12px 2px;
  --vw100: 100vw;
  --vw96p187: 96.187vw;
  --vw70: 70vw;
  --vw50: 50vw;
  --vw48: 48vw;
  --vw46: 46vw;
  --vw44: 44vw;
  --vw35p45: 35.45vw;
  --vw33p35: 33.35vw;
  --vw31: 31vw;
  --vw30: 30vw;
  --vw27: 27vw;
  --vw25: 25vw;
  --vw16p25: 16.25vw;
  --vw16: 16vw;
  --vw15p25: 15.25vw;
  --vw12: 12vw;
  --vw11p5: 11.5vw;
  --vw11: 11vw;
  --vw10: 10vw;
  --vw9p5: 9.5vw;
  --vw9: 9vw;
  --vw7p725: 7.725vw;
  --vw7p5: 7.5vw;
  --vw5p725: 5.725vw;
  --vw4: 4vw;
  --vw3p25: 3.25vw;
  --vw2p625: 2.625vw;
  --vw2: 2vw;
  --vw1p75: 1.75vw;
  --vw1p5: 1.5vw;
  --vw1p25: 1.25vw;
  --vw1: 1vw;
  --vw0p875: 0.875vw;
}

@media only screen and (min-width: 2560px) {
  :root {
    --vw100: 2560px;
    --vw96p187: 2462.39px;
    --vw70: 1792px;
    --vw50: 1280px;
    --vw48: 1228.8px;
    --vw46: 1177.6px;
    --vw44: 1126.4px;
    --vw35p45: 907.52px;
    --vw33p35: 853.75px;
    --vw31: 793.6px;
    --vw30: 768px;
    --vw27: 691.2px;
    --vw25: 640px;
    --vw16p25: 416px;
    --vw16: 409.6px;
    --vw15p25: 390.4px;
    --vw12: 307.2px;
    --vw11p5: 294.4px;
    --vw11: 281.6px;
    --vw10: 256px;
    --vw9p5: 243.2px;
    --vw9: 230.4px;
    --vw7p725: 197.76px;
    --vw7p5: 192px;
    --vw5p725: 146.56px;
    --vw4: 102.4px;
    --vw3p25: 83.2px;
    --vw2p625: 67.2px;
    --vw2: 51.2px;
    --vw1p75: 44.8px;
    --vw1p5: 38.4px;
    --vw1p25: 32px;
    --vw1: 25.6px;
    --vw0p875: 22.4px;
  }
}

@media only screen and (max-width: 280px) {
  :root {
    --vw100: 280px;
    --vw96p187: 269.324px;
    --vw70: 196px;
    --vw50: 140px;
    --vw48: 134.4px;
    --vw44: 123.2px;
    --vw35p45: 99.26px;
    --vw33p35: 93.375px;
    --vw31: 86.8px;
    --vw30: 84px;
    --vw27: 75.6px;
    --vw25: 70px;
    --vw16p25: 45.5px;
    --vw16: 44.8px;
    --vw15p25: 42.7px;
    --vw12: 33.6px;
    --vw11p5: 32.2px;
    --vw11: 30.8px;
    --vw10: 28px;
    --vw9p5: 26.6px;
    --vw9: 25.2px;
    --vw7p725: 21.63px;
    --vw7p5: 21px;
    --vw5p725: 16.03px;
    --vw4: 11.2px;
    --vw3p25: 9.1px;
    --vw2p625: 7.35px;
    --vw2: 5.6px;
    --vw1p75: 4.9px;
    --vw1p5: 4.2px;
    --vw1p25: 3.5px;
    --vw1: 2.8px;
    --vw0p875: 2.45px;
  }
}
