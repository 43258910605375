.__react_component_tooltip {
  max-width: 33vw;
}

@media (max-width: 48em) {
  .__react_component_tooltip {
    max-width: 253px;
  }
}

@media (max-width: 31.5625em) {
  .__react_component_tooltip {
    max-width: 50vw;
  }
}